import React from 'react';
import { useSelector } from 'react-redux';

import ProfileContextProvider from '../../shared/ProfileContextProvider';
import { ProfileDrawer } from './ProfileDrawer';

const ProfileV2Modal = () => {
  const showProfileV2Modal = useSelector(state => state.modals.showProfileV2Modal);

  return (
    <ProfileContextProvider>
      <ProfileDrawer show={showProfileV2Modal} />
    </ProfileContextProvider>
  );
};

export default ProfileV2Modal;
